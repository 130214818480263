.app {
  width: 100%;
}

.site-header {
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  height: 60px;
  background-color: transparent;
}

.header-contents {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.brand-logo {
  text-decoration: none;
}
.brand-logo h1 {
  color: #ebcf41;
  font-size: 1.5em;
}
.menu-container {
  width: 100%;
}
.site-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  position: relative;
}
.nav-inner {
  width: 100%;
  flex-flow: column;
  width: 250px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  display: none;
  border: 2px solid #ebcf41;
  border-top: 0;
  border-radius: 2px;
  text-align: center;
  padding: 10px 0;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  padding-bottom: 0;
}

.site-menu li {
  position: relative;
  cursor: pointer;
  color: #000;
  font-weight: 600;
  font-size: 1em;
  border-top: 2px solid #ebcf41;
}

.site-menu li:last-child {
  margin-right: 0;
  border-bottom: 0;
}

.site-menu li a:after,
li.dropdown-menu label:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 2px;
  background-color: #000;
  width: 100%;
  left: 0;
  opacity: 0;
  transition: 0.3s ease;
  border-radius: 1em;
}

.site-menu li a:hover:after,
.site-menu li a.active:hover:after,
.site-menu li a.active:after,
li.dropdown-menu:hover label:after {
  opacity: 1;
}
.site-menu li a.active,
li.dropdown-menu:hover label {
  color: #000;
}
.site-menu li > a {
  text-decoration: none;
  color: inherit;
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 10px;
}
.site-menu li:hover {
  background-color: rgb(1, 128, 23, 0.2);
}

.site-menu li:hover > a {
  color: #ebcf41;
}

.site-menu li.whatsapp-button {
  background-color: #25d366;
  padding: 0;
  width: 100%;
}

.site-menu li.whatsapp-button a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 13px 0;
}

.site-menu li.whatsapp-button a img {
  line-height: 0;
  width: auto;
  height: auto;
}

.nav-label {
  display: block;
  cursor: pointer;
}
#toggle-responsive-menu {
  display: none;
}
.hide-menu-btn {
  box-shadow: none;
  padding: 0;
  margin-bottom: 10px;
}
.menu-cta.button {
  box-shadow: none;
  width: auto;
  margin: auto;
}
.showParent {
  display: flex;
  animation: animatezoom 1s;
}

.icon {
  width: 20px;
  height: 20px;
}
.logo {
  width: 81px;
  height: 26px;
}

.hero {
  width: 100%;
  position: relative;
  background-image: url("./assets/images/content/web2r.png"),
    url("./assets/images/content/web2l.png");
  background-repeat: no-repeat;
  background-position: 0% 60%, 100% 60%;
  background-size: 200px;
  background-color: #024;
}

.scroll-container {
  display: block;
  text-align: center;
  position: absolute;
  bottom: 10%;
  left: 0;
  right: 0;
  margin: auto;
}
.scroll-container img {
  width: 30px;
  animation: vertical-shake 1s infinite;
}
.scroll-container p {
  font-size: 14px;
  text-transform: uppercase;
  color: #ebcf41;
}

/*.hero:after{

    position: absolute;
    content: "";
    background-color: rgba(0,0,0,.4);
    width: 100%;
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}*/

.carousel-content {
  position: absolute;
  z-index: 6;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
}

.carous .slider-items-container .carous-image img {
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.carous .slider-items-container {
  padding: 0 !important;
}

.hero-content-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.hero-text-side {
  width: 45%;
  margin: auto;
  margin-top: 120px;
  text-align: center;
}

.hero-gent {
  margin-top: 120px;
  text-align: center;
}
.hero-text h1 {
  font-size: 3.5em;
  margin: 20px 0;
  margin-top: 10px;
}
.hero-text span {
  text-transform: uppercase;
  color: #000;
  font-weight: 600;
  text-decoration: dashed;
}
.hero-text span.ug {
  font-size: 13px;
  font-weight: 400;
  color: #ebcf41;
}

.hero-text p {
  margin: 20px 0;
  font-size: 1em;
  color: #888;
}

.main-cta {
  text-decoration: none;
  text-align: center;
  display: block;
  margin: auto;
  width: 300px;
  background-color: #ebcf41;
  color: #000;
}
.main-cta figure img {
  width: auto;
}

.main-cta figure figcaption {
  font-size: 12px;
  text-decoration: none;
  color: #ebcf41;
  text-align: center;
}

.agent-starts-container {
  justify-content: space-around;
  flex-flow: row wrap;
  width: 100%;
}

.agent-card {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  padding: 8px;
  margin: 30px;
}
.agent-card {
  background-color: #20bf55;
  background-image: linear-gradient(315deg, #20bf55 0%, #80ff72 74%);
}
.agent-card-inner {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.c1 .agent-card-inner {
  background-color: #ff4e00;
  background-image: linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%);
}
.c2 .agent-card-inner {
  background-color: #7ee8fa;
  background-image: linear-gradient(315deg, #7ee8fa 0%, blue 74%);
}
.c3 .agent-card-inner {
  background-color: #ee8c68;
  background-image: linear-gradient(315deg, #ee8c68 0%, #eb6b9d 74%);
}

.agent-card-head {
  width: 99.5%;
  margin: auto;
  text-transform: uppercase;
  color: #fff;
  border-radius: 4px;
}

.c1 .agent-card-head {
  border-bottom: 4px solid #ec9f05;
}
.c2 .agent-card-head {
  border-bottom: 4px solid blue;
}
.c3 .agent-card-head {
  border-bottom: 4px solid #eb6b9d;
}
.agent-card-head,
.agent-card-body {
  padding: 8px;
}
.agent-card-body p {
  font-size: 1.2em;
  margin-top: -4px;
  color: #000;
}

.gradient-text {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(#d7816a, #bd4f6c);
  -webkit-background-clip: text;
  background-clip: text;
  margin-top: 5px;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-right: 0;
  font-family: Inter, sans-serif;
  font-size: 65px;
  font-weight: 900;
  line-height: 60px;
}
.green-gradient {
  background-color: #20bf55;
  background-image: linear-gradient(315deg, #20bf55 0%, #01baef 74%);
}
.requirements .text-side-text ol {
  width: 40%;
  margin: auto;
  margin-bottom: 1em;
  list-style: decimal;
}
.partners-container {
  justify-content: space-around;
  align-items: center;
}

.partners-text {
  width: 45%;
}
.partners-text p {
  font-size: 1.2em;
  margin: 16px 0;
}
.partners-image {
  width: 45%;
}

.partners-services > span {
  text-decoration: underline;
}
.partners-services .bold {
  color: #fff;
  text-decoration: none;
}

.video-testims {
  display: flex;
  flex-flow: column;
  justify-content: center;
}
.video-testims .vt div {
  width: 30%;
  height: auto;
  background-color: #bd4f6c;
  margin: 10px;
}
.video-testims .vt {
  display: flex;
  justify-content: center;
}
.featureslist-container,
.served-container,
.services-container,
.offer-container {
  justify-content: space-around;
  flex-flow: row wrap;
}
.feature-card,
.served-card,
.service-card,
.offer-card {
  width: 280px;
  margin: 20px;
  padding: 8px;
  text-align: center;
  border-radius: 2px;
}
.offer-card {
  width: 250px;
}
.service-card {
  width: 300px;
}
.feature-icon img,
.served-card img,
.testim-card img,
.service-card img,
.offer-card img {
  width: 50px;
  height: auto;
  margin: auto;
}
.feature-title {
  margin: 20px 0;
}
.served-name,
.served-desc p,
.offer-details p,
.service-title,
.offer-title {
  margin: 16px 0;
}
.service-card a {
  background-color: #ebcf41;
  color: #024;
  margin-top: 16px;
}

.sortlist {
  margin: 10px auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  text-align: center;
}
.sortlist div {
  margin: 0 20px;
}
.sortlist div a img {
  width: 200px;
}

.how-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.sideB {
  align-self: flex-end;
}
.how-text-side ul {
  width: 350px;
}
.sideA.how-text-side ul li {
  text-align: right;
}
.uiImage {
  width: 300px;
}

.how-number {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(235, 207, 65, 0.5);
  font-weight: 600;
}

.sideA .how-number {
  margin: auto;
  margin-right: 0;
}
.how-step-card {
  margin: 2em 0;
}
.how-step-card h4 {
  font-size: 1.2em;
  margin: 1em 0;
  margin-bottom: 10px;
}
.section-cta {
  text-align: center;
  animation: horizontal-shake 1s infinite;
}
.section-cta a.button {
  background-color: #ebcf41;
  color: #000;
}

.gallery-container figure {
  margin: 16px 0;
  border: 2px solid rgba(235, 207, 65, 0.5);
  padding: 8px;
  border-radius: 2px;
}
.gallery-container figure img {
  border-radius: 2px;
}
.gallery-container figure figcaption {
  font-size: 14px;
  text-align: center;
}

.faqs-container {
  flex-flow: row wrap;
  justify-content: space-around;
  display: flex;
}
.faqs-container li.faq {
  max-width: 500px;
  min-width: 300px;
  margin: 0 0 32px 0;
  padding: 4px;
  border: 1px solid rgba(235, 207, 65, 0.5);
  border-radius: 4px;
}
.page-faqs {
  padding: 20px;
}
.page-faqs .faqs-container li.faq {
  max-width: 100%;
  width: 100%;
}

.faqs-container li.faq ul {
  list-style-type: square;
}
.about-body {
  padding: 8px;
}
.about-body p {
  margin: 20px 0;
  font-size: 1.2em;
}
.hero-img-side {
  width: 40%;
  margin-top: 130px;
}
.hero-img-side .hero-slider-img {
  width: 300px !important;
}
.hero-slider-container,
.service-cards-container {
  display: flex;
}

.hero-slider-container .slick-initialized .slick-slide,
.service-cards-container .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
  margin: 0 !important;
}

.why-slider .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
  margin: 0 !important;
  align-items: flex-end !important;
  height: 300px !important;
}
.slider-items-container {
  height: 100%;
  width: 100%;
  padding: 5px 0;
}

.slider-items-container .slick-arrow {
  background-color: #ebcf41 !important;
  border-radius: 2px;
  height: 32px;
  width: 50px;
  top: 50%;
}
.service-cards-container .slick-arrow,
.why-slider .slick-arrow {
  display: none !important;
}
.carous .slick-arrow {
  background-color: transparent;
}

.slider-items-container .slick-arrow:hover {
  background-color: #000 !important;
}

.slider-items-container .slick-next,
.slider-items-container .slick-prev {
  z-index: 2;
}

.slider-items-container .slick-next {
  right: -60px;
}

.slider-items-container .slick-prev {
  left: -60px;
}

.carous .slider-items-container.center-slide-arrows .slick-arrow {
  top: 89% !important;
}
.carous .slider-items-container .slick-next {
  right: 0;
}

.carous .slider-items-container .slick-prev {
  left: 0;
}
.slider-items-container .slick-arrow.slick-disabled:before {
  opacity: 0;
}

.slider-items-container .slick-arrow.slick-disabled {
  background-color: transparent !important;
  cursor: auto !important;
}

.mottos-container,
.shared-section {
  width: 100%;
  justify-content: space-between;
}
.motto-item {
  width: 30%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  padding: 4px;
  text-align: center;
  background-color: #fff;
  border-radius: 4px;
  border: 2px solid rgb(165, 67, 74);
  font-weight: 600;
}

.motto-head {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
  border-bottom: 2px solid rgb(165, 67, 74);
  padding-bottom: 2px;
}
.motto-head h3 {
  color: #000;
}
.motto-head img {
  width: 30px;
}

.section-head h2 {
  font-size: 3.5em;
  margin-bottom: 20px;
}

.shared-section {
  justify-content: space-between;
  align-items: center;
}
.text-side {
  width: 50%;
}
.text-side-title {
  font-size: 1em;
  color: #ebcf41;
}
.text-side-subtitle {
  font-size: 3.5em;
}
.text-side-experi {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin: 20px 0;
}
.text-side-experi h4 {
  font-size: 1.5em;
}
.text-side-experi h5 {
  font-size: 6em;
  color: crimson;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-bottom: -15px;
}

.text-side-text {
  font-size: 1.2em;
  margin: 20px 0;
}

.text-side-cta {
  margin-top: 30px;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.text-side-cta h6 {
  font-size: 1em;
  text-transform: uppercase;
}
.section-head p {
  font-size: 1.2em;
}

.images-side {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-around;
}
.images-side img {
  flex: 0 0 auto;
  width: 45%;
  margin-bottom: 5%;
}

.images-side .about-big-img {
  width: 100%;
}

.text-side-cta a {
  color: #ebcf41;
  display: block;
  width: fit-content;
  margin: auto;
}
/*


.about-modal-items {
  position: absolute;
  bottom: 30px;
  right: 0;
  width: 300px;
  background-color: #fff;
  border-radius: 2px;
  display: none;
}
.about-modal-items li {
  margin: 5px 0;
  margin-top: 0;
  padding: 8px;
}
.about-modal-items li:last-child {
  margin-bottom: 0;
}
.about-modal-items li a {
  text-decoration: none;
  color: inherit;
}
.about-modal-items li:hover a h5 {
  text-decoration: underline;
}
.about-modal-items li:hover {
  background-color: #ff7e5f;
}
.about-modal-items li h5 {
  margin-bottom: 10px;
  font-size: 1em;
}
.about-modal-items li p {
  font-size: 12px;
}

*/
.what-items-container {
  display: flex;
  justify-content: space-around;
}
.what-card {
  background-color: #000;
  color: #fff;
  flex: 1 1 30%;
  margin: 30px;
  height: 100%;
  text-align: center;
  padding: 2em;
  border: 1px solid #ccc;
  display: flex;
  flex-flow: column;
  height: auto;
  align-items: center;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.what-card img {
  width: 80px;
}

.what-card h3 {
  color: #fff;
  margin: 10px 0;
  font-size: 2em;
}

.what-card p {
  margin: 20px 0;
  color: #ccc;
  font-size: 1.2em;
  text-align: justify;
}

.what-card a {
  font-size: 1.5em;
  color: #fff;
  display: flex;
  align-items: center;
}
.what-card a img {
  width: 20px;
  line-height: 0;
  margin-bottom: -5px;
}
.what-card a:hover {
  color: #ccc;
}

.testim-card {
  padding: 1em;
  width: 500px;
  position: relative;
  background-color: transparent;
}

.testim-card img {
  margin: 0 auto 20px auto;
}
.testim-card h4 {
  font-size: 1.5em;
  margin: 20px 0 5px 0;
}
.testim-card p {
  font-size: 1.2em;
}
.testimonials-container .slick-arrow {
  display: none !important;
}

.testimonials-container .slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.address-wrap,
.address-message {
  justify-content: space-between;
}
.address-message {
  align-items: baseline;
}
.social-icons li {
  margin: 0 10px;
}

.social-icons li:hover {
  animation: vertical-shake 1s linear;
}
.social-icons a {
  display: block;
  margin: 0 10px;
}
.social-icons a img {
  width: 30px;
}
.address-message a.button {
  background-color: #ebcf41;
  color: #fff;
}
.address-message a.button:hover {
  background-color: rgb(165, 67, 74);
}
.address-office {
  width: 40%;
  justify-content: right;
  display: flex;
}
.map {
  width: 58%;
}
.map iframe,
.contacts-container {
  width: 100%;
  height: 100%;
  margin-top: 30px;
}
.map iframe {
  height: 350px;
}
.contacts-item {
  display: flex;
  align-items: baseline;
  margin: 30px 0;
}
.contacts-item .contacts-text h4,
.contacts-item .contacts-text p,
.testimonial-user h4 {
  color: #000;
}
.address-wrap h3 {
  font-size: 1.5em;
}
.contacts-text h4 {
  font-size: 1.2em;
  margin-left: 4px;
}
.contacts-item img {
  width: 30px;
}

.action {
  background-image: url("./assets/images/content/app.png"),
    url("./assets/images/content/app2.png");
  background-repeat: no-repeat;
  background-position: 20% 206%, 80% -106%;
  background-size: 300px;
}

.project-container {
  justify-content: center;
  width: 350px;
}

.project-request p {
  color: #000;
  font-size: 0.8em;
}
.project-request h3 {
  color: #ebcf41;
  font-size: 3em;
  margin: 20px 0;
}

.project-request a {
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #fff;
  background-color: #ebcf41;
  font-size: 1.2em;
  text-transform: capitalize;
  width: 250px;
}

.project-request a img {
  width: 20px;
  margin-left: 2px;
}

.footer-navigation {
  justify-content: space-between;
}
.footer-nav-item {
  width: 30%;
}
.footer-nav-item h3 {
  font-size: 1.5em;
  margin-bottom: 30px;
}
.footer-nav-item h3 {
  align-items: center;
}
.footer-nav-item h3 img {
  width: 100px;
  margin-right: 4px;
}

.footer-about-container p {
  font-size: 1.2em;
  margin: 20px 0;
  text-align: justify;
}

.footer-socials {
  display: flex;
  justify-content: space-between;
}

.footer-socials li a {
  color: #fff !important;
  display: flex !important;
  align-items: center;
}

.footer-socials li {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 2px;
}
.footer-socials li:hover {
  animation: vertical-shake 1s;
}
.footer-socials li a img {
  line-height: 0;
  width: 100%;
  height: 100%;
}

li.ig-bg {
  background-color: rgb(195, 42, 163);
}
li.twi-bg {
  background-color: rgb(0, 172, 237);
}
li.fb-bg {
  background-color: rgb(59, 89, 152);
}

li.wa-bg {
  background-color: #25d366;
}
.footer-menu li {
  margin: 1em 0;
}
.footer-menu li:last-child {
  margin-bottom: 0;
}
.footer-menu li a {
  color: #ccc;
  font-size: 1.1em;
}
.footer-menu li a:hover {
  color: #ebcf41;
}
.support-item {
  display: flex;
  margin: 15px 0;
  font-size: 1.1em;
  align-items: flex-start;
}
.support-item img {
  width: 30px;
  margin-right: 4px;
}
.support-item p span,
.contacts-text p span {
  display: block;
  font-size: 0.9em;
  margin-bottom: 10px;
}
.contacts-text p span {
  font-size: 1em;
}

.activate-container ul li {
  margin: 10px 0;
}
.answer-container p {
  margin: 10px 0;
}
.comms-card {
  padding: 8px;
  margin: 20px 0;
}
.comms-card:first-child {
  margin-top: 0;
}
.comms-body p {
  color: #000;
  margin: 1em 0;
  text-align: justify;
}
.comms-body p.center {
  text-align: center !important;
}
.hero-500 {
  font-size: 12px;
  color: #ebcf41;
}
.hero-500 a {
  color: #ebcf41;
}

.renewals .section-head {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  text-transform: uppercase;
}

.renewals .section-head p span.bold{
  margin-right: 4px;
}
.ren-table {
  width: 100%;
  display: table;
  border-collapse: separate;
  border-spacing: 2px;
  border-color: #ccc;
}
.ren-table tr td{
  border: 1px solid rgba(235, 207, 65, 0.5);
}
.ren-table th{
  margin: 0;
  text-align: left;
  background-color: rgba(235, 207, 65, 0.5);
}
.ren-table th, .ren-table td{
  margin-left: 5px;
}

.warn{
  background-color: red;
}
.warn-yellow{
  background-color: yellow;
}
@media screen and (min-width: 960px) and (max-width: 1200px) {
  .hero {
    background-size: 200px;
  }
  .slider-items-container .slick-prev {
    left: 0;
  }
  .slider-items-container .slick-next {
    right: 0;
  }
  .slider-items-container .slick-arrow {
    top: 70%;
  }
  .hero-text h1,
  .text-side-subtitle {
    font-size: 2.9em;
  }
  .section-head h2 {
    font-size: 2.8em;
  }
  .partners-text p {
    font-size: 1.1em;
  }
  .partners-container {
    justify-content: space-between;
    align-items: center;
  }
  .how-text-side ul {
    width: 280px;
  }
  .social-icons li {
    margin: 0 10px;
    padding: 10px;
  }
  .width-960 {
    max-width: 960px;
    width: 100%;
  }
  .testim-card {
    width: 450px;
  }
  .action {
    background-position: 20% 206%, 80% -106%;
    background-size: 260px;
  }
  .footer-about-container p,
  .footer-menu li a,
  .support-item {
    font-size: 1em;
  }
}

@media screen and (min-width: 800px) and (max-width: 959px) {
  .hero {
    background-size: 150px;
  }
  .slider-items-container .slick-prev {
    left: 0;
  }
  .slider-items-container .slick-next {
    right: 0;
  }
  .slider-items-container .slick-arrow {
    top: 90%;
  }
  .hero-text span.ug {
    font-size: 8px;
  }
  .slider-items-container .served-card {
    width: 350px;
  }
  .hero-text h1,
  .text-side-subtitle {
    font-size: 2.4em;
  }
  .section-head h2 {
    font-size: 2.8em;
  }
  .hero-text p {
    margin: 20px 0;
    font-size: 1em;
  }
  .how-text-side ul {
    width: 250px;
  }
  .uiImage {
    width: 200px;
  }
  .social-icons li {
    margin: 0 10px;
    padding: 2px;
  }
  .footer-socials li {
    width: 30px;
    height: 30px;
  }
  .width-960,
  .width-800 {
    max-width: 800px;
    width: 100%;
  }
  .testim-card {
    width: 350px;
  }
  .testim-card p {
    font-size: 1em;
  }
  .testim-card h4 {
    font-size: 1.2em;
  }
  .action {
    background-position: 10% 206%, 90% -106%;
    background-size: 260px;
  }
  .footer-about-container p,
  .footer-menu li a,
  .support-item {
    font-size: 1em;
  }
  .agent-card {
    width: 224px;
    height: 224px;
    padding: 4px;
    margin: 16px;
  }
  .agent-card-head {
    font-size: 1em;
  }
  .partners-text {
    width: 55%;
  }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .hero {
    background-size: 120px;
  }
  .hero-text span.ug {
    font-size: 8px;
  }
  .slider-items-container .slick-prev {
    left: 0;
  }
  .slider-items-container .slick-next {
    right: 0;
  }
  .slider-items-container .slick-arrow {
    top: 70%;
  }
  .slider-items-container .served-card {
    width: 280px;
  }
  .hero-text h1,
  .text-side-subtitle {
    font-size: 2.4em;
  }
  .section-head h2 {
    font-size: 2em;
  }
  .hero-text p {
    margin: 20px 0;
    font-size: 1em;
  }
  .sortlist div a img {
    width: 150px;
  }

  .how-text-side ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .sideA.how-text-side ul li {
    width: 45%;
  }
  .sideA.how-text-side ul li:first-child {
    text-align: right;
  }
  .sideA.how-text-side ul li:last-child {
    text-align: left;
  }

  .sideA.how-text-side ul li:last-child .how-number {
    margin-right: auto;
    margin-left: 0;
  }
  .sideB.how-text-side ul li .how-number {
    margin-right: auto;
    margin-left: auto;
  }

  .how-container {
    flex-wrap: wrap;
  }
  .uiImage {
    width: 200px;
    order: -1;
    display: none;
  }
  .sideA {
    order: 1;
    width: 100%;
  }
  .sideB {
    order: 2;
    text-align: center;
    width: 100%;
  }

  .social-icons li {
    margin: 0 10px;
    padding: 2px;
  }
  .footer-socials li {
    width: 30px;
    height: 30px;
  }
  .width-960,
  .width-800,
  .width-600 {
    max-width: 600px;
    width: 100%;
  }
  .testim-card {
    width: 500px;
  }
  .testim-card p {
    font-size: 1em;
  }
  .testim-card h4 {
    font-size: 1.2em;
  }
  .action {
    background-position: 0% 100%, 100% -50%;
    background-size: 200px;
  }
  .footer-about-container p,
  .footer-menu li a,
  .support-item {
    font-size: 1em;
  }
  .agent-card {
    width: 224px;
    height: 224px;
    padding: 4px;
    margin: 16px;
  }
  .agent-card-head {
    font-size: 1em;
  }
  .partners-text {
    width: 55%;
  }
  .hero-text-side {
    width: 65%;
  }
  .shared-section,
  .partners-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .images-side {
    width: 80%;
  }
  .text-side {
    width: 80%;
    text-align: center;
    order: -1;
    margin-bottom: 40px;
  }
  .partners-text {
    width: 80%;
    text-align: center;
    margin-bottom: 50px;
  }
  .partners-image {
    width: 80%;
    order: -1;
    margin-bottom: 50px;
  }
  .footer-nav-item h3 {
    font-size: 1.2em;
  }
  .footer-navigation,
  .address-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .footer-nav-item {
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: 50px;
  }
  .footer-nav-item:last-child {
    margin-bottom: 0;
  }
  .footer-about {
    order: -1;
    width: 300px;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .footer-support {
    order: 2;
    width: 100%;
    background-color: #000;
    padding: 1em;
    border-radius: 4px;
  }
  .footer-nav-item h3 {
    text-align: center;
    display: block;
  }
  .footer-nav-item h3 img {
    margin-right: 0;
    margin: auto;
  }
  .footer-about-container p {
    text-align: center;
  }
  .support-item,
  .contacts-item {
    display: block;
  }
  .feature-card {
    width: 240px;
  }
  .address-office {
    width: 80%;
    order: -1;
    text-align: center;
  }
  .map {
    width: 100%;
    text-align: center;
  }
  .contacts-item {
    justify-content: center;
    align-items: center;
    width: 350px;
    margin: auto;
    margin-bottom: 50px;
  }
  .section-height-300 {
    height: 250px;
  }
  .title {
    font-size: 1.5em;
  }
  .address-office {
    justify-content: center;
  }
  .offer-card {
    width: 225px;
  }
  .served-card {
    width: 240px;
  }
  .featureslist-container,
  .served-container,
  .services-container,
  .offer-container {
    justify-content: space-between;
    flex-flow: row wrap;
  }
}

@media screen and (max-width: 599px) {
  .hero {
    background-image: url("./assets/images/content/web2.png");
    background-size: 100px;
    background-position: 50% 85%;
  }
  .slider-items-container .slick-prev {
    left: 0;
  }
  .slider-items-container .slick-next {
    right: 0;
  }
  .slider-items-container .slick-arrow {
    top: 85%;
  }
  .slider-items-container .served-card {
    width: 280px;
  }
  .hero-text span.ug {
    font-size: 9px;
  }
  .hero-text h1,
  .text-side-subtitle {
    font-size: 2em;
  }
  .section-head h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  .about-body p {
    font-size: 1em;
  }
  .hero-text p {
    margin: 20px auto;
    font-size: 1em;
    width: 100%;
  }
  .requirements .text-side-text ol {
    width: 60%;
  }
  .how-text-side ul {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
  }

  .sideA.how-text-side ul li {
    width: 100%;
  }
  .how-text-side ul li {
    text-align: center !important;
  }

  .how-text-side ul li .how-number {
    margin: auto !important;
  }
  .how-container {
    flex-wrap: wrap;
  }
  .uiImage {
    width: 200px;
    order: -1;
    display: none;
  }
  .sideA {
    order: 1;
    width: 100%;
  }
  .sideB {
    order: 2;
    text-align: center;
    width: 100%;
  }
  .section-cta {
    padding-top: 10px;
  }
  .social-icons li {
    margin: 0 10px;
    padding: 2px;
  }
  .footer-socials li {
    width: 30px;
    height: 30px;
  }
  .width-960,
  .width-800,
  .width-600 {
    max-width: 550px;
    width: 100%;
  }
  .testim-card {
    width: 95%;
  }
  .testim-card p {
    font-size: 1em;
  }
  .testim-card h4 {
    font-size: 1.2em;
  }
  .action {
    background-position: 0% 150%, 100% -50%;
    background-size: 140px;
  }
  .project-container {
    justify-content: center;
    width: 100%;
  }
  .project-request h3 {
    font-size: 2em;
  }
  .footer-about-container p,
  .footer-menu li a,
  .support-item {
    font-size: 1em;
  }
  .agent-card {
    width: 250px;
    height: 250px;
    padding: 4px;
    margin: 16px;
  }
  .agent-card-head {
    font-size: 1em;
  }
  .partners-text {
    width: 55%;
  }
  .hero-text-side {
    width: 100%;
  }
  .shared-section,
  .partners-container {
    flex-wrap: wrap;
    justify-content: center;
  }
  .images-side {
    width: 100%;
  }
  .text-side {
    width: 100%;
    text-align: center;
    order: -1;
    margin-bottom: 40px;
  }
  .partners-text {
    width: 100%;
    text-align: center;
  }
  .partners-image {
    width: 100%;
    order: -1;
    margin-bottom: 50px;
  }
  .video-testims .vt div {
    width: 70%;
  }
  .footer-nav-item h3 {
    font-size: 1.2em;
  }
  .footer-navigation,
  .address-wrap {
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .footer-nav-item {
    width: 100%;
    order: 1;
    text-align: center;
    margin-bottom: 50px;
  }
  .footer-nav-item:last-child {
    margin-bottom: 0;
  }
  .footer-about {
    order: -1;
    width: 300px;
    margin: auto;
    margin-bottom: 50px;
    text-align: center;
  }
  .footer-support {
    order: 2;
    width: 100%;
    background-color: #000;
    padding: 1em;
    border-radius: 4px;
  }
  .footer-nav-item h3 {
    text-align: center;
    display: block;
  }
  .footer-nav-item h3 img {
    margin-right: 0;
    margin: auto;
  }
  .footer-about-container p {
    text-align: center;
  }
  .support-item,
  .contacts-item {
    display: block;
  }
  .feature-card {
    width: 240px;
  }
  .address-office {
    width: 100%;
    order: -1;
    text-align: center;
  }
  .map {
    width: 100%;
    text-align: center;
  }
  .contacts-item {
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: auto;
    margin-bottom: 50px;
  }
  .section-height-300 {
    height: 200px;
  }
  .address-message {
    flex-wrap: wrap;
  }
  .address-message a.button {
    width: 300px;
    margin: auto;
    margin-bottom: 20px;
  }
  .social-icons {
    width: 100%;
    justify-content: center;
  }
  .title {
    font-size: 1.2em;
    text-align: center;
  }
  .offer-notice {
    font-size: 12px;
  }
  .minus-margin-top-150 {
    margin-top: -115px;
  }
  .scroll-container {
    bottom: 5%;
  }
}
